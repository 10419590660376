<template>
  <div>
    <b-overlay :show="show_loader" rounded="sm">
      <b-card-code :title="$t('User.user_list')">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t("search") }}</label>
              <b-form-input
                v-model="searchTerm"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <vue-good-table
          :columns="columns"
          :rows="users"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          styleClass="vgt-table table-hover"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: id -->
            <span v-if="props.column.field == 'id'">
              <span> {{ props.row.id }}</span>
            </span>
            <!-- Column: avatar -->
            <span v-if="props.column.field == 'avatar'">
              <b-avatar
                size="32"
                :text="avatarText(props.row.name + ' ' + props.row.last_name)"
              />
            </span>
            <!-- Column: name -->
            <span v-if="props.column.field == 'name'">
              <span> {{ props.row.name }}</span>
            </span>
            <!-- Column: Last Name -->
            <span v-if="props.column.field == 'last_name'">
              <span> {{ props.row.last_name }}</span>
            </span>
            <!-- Column: email -->
            <span v-if="props.column.field == 'email'">
              <span> {{ props.row.email }}</span>
            </span>
            <!-- Column: company -->
            <span v-if="props.column.field == 'company'">
              <span> {{ props.row.company }}</span>
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  {{ $t("showing") }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">
                  {{ $t("showing_pt2", { total_entries }) }}
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        <div class="custom-search d-flex justify-content-end">
          <download-csv
            :data="users"
            name="user_list.csv"
            :separator-excel="true"
          >
            <b-button
              :disabled="users.length == 0"
              class="d-flex align-items-center"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              pill
            >
              <feather-icon icon="DownloadIcon" class="mr-50" />
              CSV
            </b-button>
          </download-csv>
        </div>
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BFormGroup,
  BFormInput,
  BButton,
  BPagination,
  BFormSelect,
  BOverlay,
  BAvatar,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { avatarText } from "@core/utils/filter";
import "vue-good-table/dist/vue-good-table.css";
import Ripple from "vue-ripple-directive";

import { getAllUsers } from "@api/user";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BPagination,
    BOverlay,
    BAvatar,
    BCardCode,
    VueGoodTable,
  },
  setup() {
    return { avatarText };
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show_loader: true,
      columns: [
        {
          label: "id",
          field: "id",
          hidden: true,
        },
        {
          field: "avatar",
          sortable: false,
          width: "50px",
        },
        {
          label: this.$t("User.Name"),
          field: "name",
        },
        {
          label: this.$t("User.Last_name"),
          field: "last_name",
        },
        {
          label: this.$t("User.Email"),
          field: "email",
        },
        {
          label: this.$t("User.Company"),
          field: "company",
        },
      ],
      users: [],
      searchTerm: "",
      pageLength: 10,
      total_entries: null,
    };
  },
  created() {
    this.getData("visitor");
  },
  methods: {
    getData(role) {
      const $this = this;
      this.show_loader = true;
      getAllUsers(role)
        .then((data) => {
          $this.users = data.map((item) => {
            return {
              id: item.id,
              name: item.name,
              last_name: item.last_name,
              email: item.email,
              company: item.company,
            };
          });
          $this.total_entries = data.length;
          $this.show_loader = false;
        })
        .catch((error) => {
          console.log(error);
          $this.show_loader = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>

import { api } from "./index";

export const getAllUsers = (role) => {
  return new Promise((resolve, reject) => {
    api
      .get(`users?role=${role}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const invite = (formData) => {
  return new Promise((resolve, reject) => {
    api
      .post(`invitations`, formData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const acceptInvite = (formData) => {
  return new Promise((resolve, reject) => {
    api
      .post(`invitations/accept`, formData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const getInvitations = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`invitations`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const deleteInvitations = (id) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`invitations/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const resendInvitations = (id) => {
  return new Promise((resolve, reject) => {
    api
      .post(`invitations/${id}/resend`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const deleteUser = (id) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`users/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};